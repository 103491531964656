import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowledgeCentreSlider.css";
import "./ResponsiveKnowledgeCentreSlider.css";
import $ from 'jquery';
import { getBlogData, getProductVideoData } from '../../../Redux/Action/Action';
import parse from 'html-react-parser';
const host = process.env.REACT_APP_API_SERVER;
const JavascriptVoid = (event) => {
    event.preventDefault();
};
function pauseVideo() {

    let videoId = document.getElementById("iframeyt");
    videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')
}

const KnowledgeCentreSlider = () => {



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductVideoData());
        dispatch(getBlogData());
    }, []);

    const productVideoData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.productVideoDataList?.data) : []);
    const blogData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.blogDataList?.data) : []);
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const [currentBlogUrl, setCurrentBlogUrl] = useState("");


    const navigate = useNavigate();
    const handleClick = (name) => {
        if (name != undefined) {
            navigate(`/blog-details/${name}`);
        }
    }

    const updateVideoUrl = (url) => {
        const videoId = url?.split('/')[3]?.split('?')[0];

        // Construct the embed URL format
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

        // Update the current video URL state with the new embed URL
        setCurrentVideoUrl(embedUrl);
    };

    const updateBlogUrl = (url) => {
        const BlogId = url?.split('/')[3]?.split('?')[0];

        // Construct the embed URL format
        const embedUrl = `https://www.youtube.com/embed/${BlogId}`;

        // Update the current Blog URL state with the new embed URL
        setCurrentBlogUrl(embedUrl);
    };

    const Slider_group = {
        dots: false,
        nav: true,
        infinite: productVideoData?.length > 3 && blogData?.length > 3,
        infinite: true,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        initialSlide: 0,
        arrows: true,
        swipe: true,
        // centerMode: true,
        centerPadding: '10px',
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: productVideoData?.length > 3 && blogData?.length > 3,
                    dots: false,
                    
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const Blog_group = {
        dots: false,
        nav: true,
        infinite: blogData?.length > 3,
        infinite: true,
        speed: 500,
        lazyLoad: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        initialSlide: 0,
        arrows: true,
        swipe: true,
        // centerMode: true,
        centerPadding: '10px',
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: blogData?.length > 3,
                    dots: false,
                    
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className='knowledgec-entre-slider-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h3>Knowledge Centre</h3>
                    </div>
                    <div className="heading-tabs-parent">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#technical-video" type="button" role="tab" aria-controls="home" aria-selected="true">Technical Product video</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link blogs-btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#blogs-tabs" type="button" role="tab" aria-controls="profile" aria-selected="false">Blogs</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="technical-video" role="tabpanel" aria-labelledby="home-tab">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        {productVideoData?.length != "0" ?
                                            <Slider {...Slider_group} className='slider-parent-dv'>
                                                {productVideoData?.map((data) => (
                                                    <div className='slider-item-dv'  >
                                                        <div className="slider-item-img" style={{ cursor: "pointer" }} onClick={() => updateVideoUrl(data?.link)} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                            <img src={data?.image ? `${host}/${data?.image}` : ""} alt={data?.title} className='img-fluid' />
                                                            <div className="play-btn-youtube">
                                                                <a>
                                                                    <img src="../images/youtube-play-btn.png" alt={data?.url} className='img-fluid ' />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="slider-item-heading px-3" >
                                                            <h4>{data?.title} </h4>
                                                            <p>{data?.subtitle}</p>
                                                            {data?.url && <p>Know more: <a href={data?.url}>{data?.url}</a></p>}
                                                            {data?.phone1 && <p>Call: <a href={`tel:${data?.phone1}`}>{data?.phone1}</a></p>}
                                                            {data?.phone2 && <p>Customer care: <a href={`tel:${data?.phone2}`}>{data?.phone2}</a></p>}
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                            :
                                            ""}
                                    </div>
                                </div>
                                <div className="footer-view-all-btn">
                                    <Link to="/knowledge-center"><b>View All</b> <i className="fa fa-long-arrow-right" aria-hidden="true"></i></Link>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="blogs-tabs" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        {blogData?.length != "0" ?
                                            <Slider {...Blog_group} className='slider-parent-dv'>
                                                {blogData?.map((data) => (
                                                    <div className='slider-item-dv'>
                                                        <div className="slider-item-img" onClick={() => { handleClick(data?.slug) }} style={{ cursor: "pointer" }}>
                                                            <img src={data?.image ? `${host}/${data?.image}` : "../images/kc-1.png"} alt={data?.title} className='img-fluid' />
                                                            {/* <div className="play-btn-youtube">
                                                                <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" onClick={() => updateBlogUrl(data?.link)}>
                                                                    <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                                                </a>
                                                            </div> */}
                                                        </div>
                                                        <div className="slider-item-heading" onClick={() => { handleClick(data?.slug) }} style={{ cursor: "pointer" }}>
                                                            <h5>{data?.title ? data?.title : "DIFFRENCE BETWEEN MPPT & PWM"}</h5>
                                                            {data?.summary ? parse(data?.summary) : <p>Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.</p>}
                                                            <p className='mt-2'><b>{new Date(data?.createdAt).toDateString()}</b></p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                            :
                                            ""}
                                    </div>
                                </div>
                                <div className="footer-view-all-btn">
                                    <Link to="/knowledge-center"><b>View All</b></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {/* ================================modal 1 start here============================ */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Video Heading</h5>
                            <button type="button" onClick={pauseVideo} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe id='iframeyt' className='yt_player_iframe' width="100%" height="400" src={currentVideoUrl} title="PWM vs MPPT - Which one to choose for your application?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Understood</button>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* ================================modal end here============================== */}
            {/* ================================modal 2 start here============================ */}
            <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdrop1Label" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdrop1Label">Video Heading</h5>
                            <button type="button" onClick={pauseVideo} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe id='iframeyt' className='yt_player_iframe' width="100%" height="400" src={currentBlogUrl} title="PWM vs MPPT - Which one to choose for your application?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Understood</button>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* ================================modal end here============================== */}
        </>
    )
}

export default KnowledgeCentreSlider