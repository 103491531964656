import { React, useEffect } from 'react'
import BlogBanner from './BlogBanner/BlogBanner'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import BlogContent from './BlogContent/BlogContent'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { getAllBannerData } from '../../Redux/Action/Action'
import { Helmet } from "react-helmet";
const Blog = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getAllBannerData());

    }, []);

    const bannerData = useSelector((state) => (state?.Reducer) ? (state?.Reducer?.allBannerDataList?.data?.[0]) : []);


    return (
        <>
            <Helmet>
                {/* Page Title and Meta Tags */}
                <title>Blog | EAPRO</title>
                <meta
                    name="description"
                    content="Stay updated with the latest news, trends, and expert insights on solar energy at EAPRO blog. Discover helpful tips, product updates, and sustainability solutions for a greener future."
                />
                <meta name="keywords" content="Blog" />
                <meta name="robots" content="INDEX,FOLLOW" />
                <link rel="canonical" href="https://www.eapro.in/blog/" />

              
            </Helmet>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <BlogBanner bannerData={bannerData} />
            <BlogContent />
            <Footer />
        </>
    )
}

export default Blog